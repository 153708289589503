import React from 'react'
import get from 'lodash.get'
import { injectIntl } from 'gatsby-plugin-react-intl'
import HeroSlider from 'components/HeroSlider'
import BestSellerSlider from 'components/NewHome/BestSellerSlider'
import { Spacing, SharedStyles, Container } from '../../../kenra-storybook/index'
import { Tabs } from 'components/CustomLanding/MousseLandingPage/Tabs'
const { StPageTitle } = SharedStyles
import styled from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import Blocks from './Section1DataBlocks'

function parseFragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value
                    break
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Subtitle':
                    returnData['subtitle'] = fragmentData.value
                    break
                case 'Text':
                    returnData['text'] = fragmentData.value
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        )
                    }
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            title: returnData.title,
            text: returnData.text,
            subtitle: returnData.subtitle,
        }
    })

    return fragmentDataMap
}

const StSectionTitle = styled.div`
    padding: 8px 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.07em;
    h1 {
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 115%;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 16px;
            @media (min-width: ${settings.bp.small.view}) {
                font-size: 25px;
            }
    }
`

const KenraCareLanding = ({
    intl,
    page,
    allShopifyProducts,
    allGoogleSheetProductsRow,
    allWhite,
}) => {
    const {
        section1Title,
        section1Subtitle,
        section1Background,
        tabs,
        carouselTitle,
        carouselSlides,
        stylistFavoritesProducts,
    } = page

    let blockData = parseFragments(page.section1Data.fragments, intl)

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSlider slides={page.banner} />
            </Spacing>

            {section1Title && <Spacing removeSpaceBottom>
                <Container style={{ width: '100%' }}>
                    <StPageTitle style={{ marginBottom: '12px' }}>
                        <h1
                            style={{
                                fontSize: '25px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '115%',
                                letterSpacing: '2px',
                                textTransform: 'uppercase',
                            }}
                        >
                            {section1Title}
                        </h1>
                        {section1Subtitle && <h2
                            style={{
                                marginTop: 20,
                                marginBottom: 57,
                                fontSize: '21px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '115%',
                                maxWidth: '650px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            {section1Subtitle}
                        </h2>}
                        {section1Background?.localFile?.publicURL && <img style={{ width: '100%', maxWidth: '1200px' }} src={section1Background.localFile.publicURL} />}
                    </StPageTitle>
                </Container>
            </Spacing>}

            <Spacing removeSpaceBottom>
                <Container>
                    <StPageTitle style={{ marginBottom: '12px' }}>
                        <h1
                            style={{
                                fontSize: '25px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '115%',
                                letterSpacing: '2px',
                                textTransform: 'uppercase',
                            }}
                        >
                            {get(page, 'title')}
                        </h1>
                    </StPageTitle>
                </Container>
            </Spacing>

            {tabs && <Tabs grey={true} tabs={tabs} useOptionsTitle={true} />}

            {
                carouselSlides && carouselSlides.length > 0 &&
                <Spacing removeSpaceBottom>
                    <Container>
                        {carouselTitle && <StSectionTitle style={{ marginBottom: '12px' }}>
                            <h1>
                                {carouselTitle}
                            </h1>
                        </StSectionTitle>}

                        <HeroSlider
                            disableAutoplay={true}
                            sliderConfig={{
                                settings: {
                                    pagination: {
                                        el: '.swiper-pagination',
                                    },
                                },
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                },
                            }}
                            additionalSlideStyle={{ paddingBottom: '40px' }}
                            slides={carouselSlides} />

                    </Container>
                </Spacing>}

            {blockData?.length > 0 && (
                <Spacing removeSpaceBottom>
                    <Blocks items={blockData} />
                </Spacing>
            )}


            {stylistFavoritesProducts && stylistFavoritesProducts.length > 0 && (
                <Spacing
                    removeSpaceBottom
                    removeSpaceTop
                    style={
                        allWhite
                            ? { backgroundColor: '#FFF', marginTop: '-15px' }
                            : { backgroundColor: '#F8F8F7', marginTop: '-15px' }
                    }
                >
                    <BestSellerSlider
                        title={page.stylistFavoritesTitle}
                        subtitle={''}
                        products={page.stylistFavoritesProducts}
                        shopifyProducts={allShopifyProducts}
                        allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                        magicMousse={true}
                        alignCenter={true}
                    />
                </Spacing>
            )}
        </>
    )
}

export default injectIntl(KenraCareLanding)
