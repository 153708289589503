import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import KenraCareLanding from 'components/CustomLanding/KenraCareLandingPage/KenraCareLandingPage';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function KenraCareLandingPage(props) {
    let {
        data: {
            contentfulCustomLandingPage,
            allShopifyProduct: { nodes: allShopifyProducts },
            allGoogleSheetProductsRow,
            contentfulProduct,
        },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCustomLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCustomLandingPage, 'seoListing.seoTitle')}
                slug={'kenra-care'}
            />
            <KenraCareLanding
                page={contentfulCustomLandingPage}
                allShopifyProducts={allShopifyProducts}
                allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                contentfulProduct={contentfulProduct}
                allWhite={true}
            />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "kenra-care" }
        ) {
            ...ContentfulCustomLandingPageFields
            tabs {
                id
                text {
                    raw
                }
                title
                optionData {
                    id
                    title
                    newImages {
                        id
                        fragments {
                            value
                            type
                            key
                        }
                    }
                }
            }
            comparison {
                rows
                items {
                    rows
                    product
                }
            }
            comparisonChartTitle
            section1Data {
                fragments {
                    key
                    value
                    type
                }
            }
        }
        allShopifyProduct {
            nodes {
                ...ShopifyProductFields
            }
        }
        allGoogleSheetProductsRow {
            nodes {
                sku
                caAssortment
                canIdh
            }
        }
    }
`;
