import React from 'react'

import styled, { css } from 'styled-components'

import settings from '../../../kenra-storybook/global/settings'
import * as SharedStyles from '../../../kenra-storybook/global/SharedStyles'
import CustomLink from 'components/Link'
import { ButtonPlay } from 'kenra-storybook'
const { StLinkMore } = SharedStyles

const StMasonaryColumn = styled.section.attrs(props => ({
    className: 'StMasonaryColumn',
}))`
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    width: 100%;

    @media (min-width: ${settings.bp.medium.view}) {
        width: 50%;
    }

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: ${settings.bp.small.view}) {
            line-height: 1.79;
            margin: 0 0 20px;
            font-size: 25px;
            ${props =>
        props.smaller &&
        css`
                @media (min-width: ${settings.bp.small.view}) {
                    margin-top: 0px;
                }
                `};
        }
    }


    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
            letter-spacing: 0.08em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        //margin-top: 24px;
    }

    // background-color: red;
    // border: 2px solid black;
    ${props =>
        props.smaller &&
        css`
        @media (max-width: ${settings.bp.small.view}) {
        h2 {
            font-size: 18.44px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 120% !important;
            letter-spacing: 1.317px;
            text-transform: uppercase;
        }
        }
        `};
`

const StMasonaryItemImg = styled.div.attrs(props => ({
    className: 'StMasonaryItemImg',
}))`
    width: 100%;
    max-width: 642px;
    margin: auto;
    display: flex;
    justify-content: center;
    position: relative;
    @media (max-width: ${settings.bp.small.view}) {
        padding-left: 27px;
        padding-right: 27px;
    }
    ${props => props.isVideo && css`
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            pointer-events: none;
            transition: opacity 0.3s;
            opacity: 25%;
            @media (min-width: ${settings.bp.small.view}) {
                background-color: ${settings.bgOverlay};
            }
        }
        &:hover:before {
            opacity: 0;
        }
    `}

    img {
        max-width: 100%;
        object-fit: cover;
        @media (min-width: ${settings.bp.small.view}) {
            max-height: 900px;
            ${props =>
        props.smaller &&
        css`
                max-width: 80%;
                @media (min-width: ${settings.bp.small.view}) {
                  max-height: 600px;
                }
                `};
            }
    }
`

const StMasonaryItemInternalImg = styled.img.attrs(props => ({
    className: 'StMasonaryItemInternalImg',
}))`
    width: 100%;
`

const StBlockContainer = styled.div.attrs(props => ({
    className: 'StBlockContainer',
}))`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // @media (min-width: ${settings.bp.medium.view}) {
    //     flex-direction: row;
    // }
`


function Item(props) {
    const { image, title, text, subtitle } = props
    const isVideo = text && text.includes('/video/')
    return (
        <StMasonaryColumn>
            {
                subtitle && (
                    <h2>{subtitle}</h2>
                )
            }

            {text && title && (
                <StLinkMore color='#A3E3FF' rounded invertTextColor center bottomMargin morePadding hoverColor={'#C8F5FF'} responsiveFont>
                    <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={text}>
                        {title}
                    </CustomLink>
                </StLinkMore>
            )}
            {image && !isVideo && (
                <StMasonaryItemImg>
                    <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={text}>
                        <StMasonaryItemInternalImg src={image} />
                    </CustomLink>
                </StMasonaryItemImg>
            )}
            {image && isVideo && (
                <ButtonPlay removeCircle>
                    <StMasonaryItemImg isVideo={true}>
                        <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={text}>
                            <StMasonaryItemInternalImg src={image} />
                        </CustomLink>
                    </StMasonaryItemImg>
                </ButtonPlay>
            )}
        </StMasonaryColumn>
    )
}

export default function Blocks(props) {
    const { items } = props
    if (!items) {
        return null
    }
    return (<StBlockContainer>
        {items.map((item, index) => {
            return <Item key={index} {...item} />
        })}
    </StBlockContainer>)
}
