import React, { Fragment, useState } from 'react'
import { Spacing, FilterMousse } from '../../../kenra-storybook/index'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'

const StTabsContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        display: flex;
        flex: 1;
        flex-direction: row;
    }
`

const StTabsHeroImageContainer = styled.div`
    width: 100%;

    @media (min-width: ${settings.bp.small.view}) {
        width: 50%;
    }
`

const StTabsImageWrapper = styled.div`
    position: relative;
    height: 0;
    padding-top: calc(352 / 390 * 100%);
`

const StTabsBeforeAndAfterContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100%;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 20px;

    @media (min-width: ${settings.bp.small.view}) {
        max-width: 80%;
        margin-top: 10%;
        margin-bottom: 0px;
    }
`

export const Tabs = props => {
    const { tabs, grey, useOptionsTitle } = props
    const filters = tabs?.map(tab => {
        return tab.title
    })
    const [currentTab, setCurrentTab] = useState(0)
    return (
        <Spacing removeSpaceTop removeSpaceBottom>
            <Spacing
                removeSpaceBottom
                removeSpaceTop
                style={{ maxWidth: '90%', margin: 'auto', overflow: 'scroll' }}
            >
                <FilterMousse
                    items={tabs.map((tab, index) => {
                        return {
                            key: index,
                            text: tab.title,
                            onClick: () => {
                                setCurrentTab(index)
                            },
                            isActive: tabs[currentTab].title === filters[index],
                        }
                    })}
                    ignoreMobileActive
                />
            </Spacing>

            {tabs?.map((tab, index) => {
                if (tabs[currentTab].title === filters[index]) {
                    let optionData = tab?.optionData?.[0]
                    if (!optionData) {
                        return <Fragment key={`tab-${index}`}></Fragment>
                    }
                    let hero = optionData.newImages.fragments[0].find(
                        a => a.key == 'Image'
                    ).value
                    let beforeAfter = optionData.newImages.fragments[1].find(
                        a => a.key == 'Image'
                    ).value
                    return (
                        <StTabsContainer key={`tab-${index}`} style={grey ? { backgroundColor: '#FCFCFC' } : {}}>
                            <StTabsHeroImageContainer>
                                <StTabsImageWrapper>
                                    <img
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            maxWidth: '100%',
                                            height: '100%',
                                        }}
                                        src={hero}
                                    />
                                </StTabsImageWrapper>
                            </StTabsHeroImageContainer>

                            <div style={{ display: 'flex', flex: 1 }}>
                                <StTabsBeforeAndAfterContainer>
                                    <h3
                                        style={useOptionsTitle ? {
                                            textAlign: 'center',
                                            fontWeight: '500',
                                            letterSpacing: '1.714px',
                                            maxWidth: '90%',
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            marginBottom: '18px',
                                        } : {
                                            textAlign: 'center',
                                            fontWeight: '500',
                                            letterSpacing: '1.714px',
                                            maxWidth: '90%',
                                            marginRight: 'auto',
                                            marginLeft: 'auto',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        {useOptionsTitle ? optionData.title : tab.title}
                                    </h3>
                                    <img src={beforeAfter} />
                                </StTabsBeforeAndAfterContainer>
                            </div>
                        </StTabsContainer>
                    )
                }
            })}
        </Spacing>
    )
}
